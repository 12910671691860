import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';

import PageDefault from '@templates/page-template-default';

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      pageInfo {
        name
        slug {
          current
        }
      }
      id
      _rawBlocks(resolveReferences: { maxDepth: 20 })
      seo {
        ...SeoFragment
      }
      blocks {
        ... on SanityBannerSimple {
          ...BannerSimpleFragment
        }
        ... on SanityBannerSmall {
          ...BannerSmallFragment
        }
        ... on SanityContactForm {
          ...ContactFormFragment
        }
        ... on SanityClientIntakeForm {
          ...ClientIntakeFormFragment
        }
        ... on SanityContentNarrow {
          ...ContentNarrowFragment
        }
        ... on SanityContent {
          ...ContentFragment
        }
        ... on SanityContentWithImage {
          ...ContentWithImageFragment
        }
        ... on SanityContentColumns {
          ...ContentColumnsFragment
        }
        ... on SanityHeroBusinessCard {
          ...HeroBusinessCardFragment
        }
        ... on SanityHeroSubPage {
          ...HeroSubPageFragment
        }
        ... on SanityLocationsByGroup {
          ...LocationsByGroupFragment
        }
        ... on SanityLocationsMap {
          ...LocationsMapFragment
        }
        ... on SanityPosts {
          ...PostsFragment
        }
      }
    }
  }
`;

const PageTemplate = (props) => {
  const pageData = props.data.page; // Grab the nested page data because it is most useful to us
  _.omit(props, 'data'); // Remove the data grabbed above form 'props', since we don't want to send it to the component twice
  const pageProps = props;

  return <PageDefault pageProps={pageProps} pageData={pageData} />; // Send specific page data.  Also send the page props (sans the data) so that we still have access to context and other things we may want.
};

export default PageTemplate;
